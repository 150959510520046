import Button from '@/components/core/Button'
import CloseIcon from '@/components/icons/CloseIcon'
import type { DivProps } from '@/types/common'
import clsx from 'clsx'
import styles from './ModalHeader.module.scss'

type ModalHeaderProps = { split?: boolean; onCloseHandler?: () => void } & DivProps
const ModalHeader = ({ onCloseHandler, split = false, className, children, ...props }: ModalHeaderProps) => (
  <div className={clsx(styles.modal_header, split && styles.split, className)} {...props}>
    <div className={styles.content}>{children}</div>
    {onCloseHandler && (
      <Button onClick={onCloseHandler} className={styles.button_close} variant="ghost" aria-label="Close modal">
        <CloseIcon size={18} />
      </Button>
    )}
  </div>
)

export default ModalHeader
