'use client'

import SignupButton from '@/components/core/SignupButton'
import { EventContext } from '@/components/providers/eventContext'
import type { GAEvent } from '@/types/common'
import { usePathname } from 'next/navigation'

const getGaEvent = (pathname: string): GAEvent => {
  if (pathname.includes('/landing/')) {
    return { name: 'community_page_signup_click', page_name: pathname.replace('/landing/', '') }
  }

  return { name: 'signup_click' }
}

const CtaButton = () => {
  const pathname = usePathname()
  return (
    <EventContext.Provider value={{ component: 'header', ...getGaEvent(pathname) }}>
      <SignupButton>Join / Sign In</SignupButton>
    </EventContext.Provider>
  )
}

export default CtaButton
